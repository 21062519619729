/**
 * Composables for editing the user
 */
import { collection, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { User, UserPublic } from "~~/types/types";
import { createCollection } from "./useFirebase";
import { Buffer } from 'buffer';

/**
 * Returns the user with the given email
 * @param email 
 * @returns UserPublic | null
 */
export async function useUserPublicByEmail(email: string) {
  if (!crypto.subtle) {
    console.warn("Crypto API not available")
    return null
  }
  // we use the public table which can be read by everyone
  const collectionRef = createCollection<UserPublic>("users-public")
  const msgBuffer = new TextEncoder().encode(email.toLowerCase())
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  const q = query(
    collectionRef,
    where("emailHash", "==", Buffer.from(hashBuffer).toString("base64"))
  );
  const docsSnapshot = await getDocs(q)

  if (docsSnapshot.empty) {
    return null;
  } else {
    return docsSnapshot.docs[0].data()
  }
}